import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  CloseButton,
  Divider,
  Drawer,
  Flex,
  Group,
  Modal,
  Stack,
  Text,
  Textarea,
  Title,
  useMantineTheme,
} from '@mantine/core';
import classes from './AddOnsStep.module.scss';
import { AddOnFormValues, AttendeeAddonInputType, PerSessionAddonList } from 'interfaces';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import SessionSelectContent from './SessionSelectContent/SessionSelectContent';
import {
  getAddOnInput,
  getExistingSelectedSessions,
  initialSessions,
  noSessionsAreChecked,
  totalAmountAdded,
  totalPrice,
} from './AddOnsStep.utils';
import { useForm } from '@mantine/form';
import { Pebbles } from '@ui';
import { formatPenceToPounds } from 'utils/formatPrice';
import { Actions, trackAction } from 'utils/amplitude';
import { BookingAddons } from './BookingAddon';
import {
  Person,
  Plus,
  Trash,
  X,
  CaretDown,
  PencilSimple,
  CheckCircle,
} from '@phosphor-icons/react';
import { useCheckoutDetailsContext } from 'context/CheckoutDetailsContext';
import { StepperEnum } from 'enums';
import { executeScroll } from '../CheckoutDetailsUtils';
import SelectedSessionsList from '../CheckoutAttendees/SelectedSessionsList/SelectedSessionsList';

interface IAddOnsStepProps {
  hideEndTimes?: boolean;
}

type SelectedSessionInfo = {
  addOn: PerSessionAddonList;
  addOnIndex: number;
  sessionIndex: number;
};

const AddOnsStep: React.FC<IAddOnsStepProps> = ({ hideEndTimes = false }) => {
  const { basket, setActiveStep, basketSelectAddonsMutation, scrollRef, basketMutationLoading } =
    useCheckoutDetailsContext();

  const { classes: classesObj, tickets } = basket;

  const handleUpdateAddOns = async (inputData: AttendeeAddonInputType[] | undefined) => {
    await basketSelectAddonsMutation({
      variables: {
        input: {
          id: basket.id,
          addons: inputData,
        },
      },
    });
  };

  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const [opened, { open, close }] = useDisclosure(false);

  const getActiveItem = useMemo(() => {
    return classesObj?.[0].id ? classesObj?.[0].id : null;
  }, [classesObj]);

  const [activeItem, setActiveItem] = useState<string | null>(getActiveItem);

  useEffect(() => {
    setActiveItem(getActiveItem);
  }, [getActiveItem]);

  const [activeSessionInfo, setActiveSessionInfo] = useState<SelectedSessionInfo | null>(null);

  const filterSessionsWithoutAvailableSessions = (
    sessions: PerSessionAddonList[],
  ): PerSessionAddonList[] => {
    return sessions.filter(
      (sessionAddOn) => sessionAddOn.availableSessions && sessionAddOn.availableSessions.length > 0,
    );
  };

  const initialAddOnValues = tickets.flatMap((ticket) => {
    return ticket.addons?.map((addOn) => {
      return {
        uniqueId: `${ticket.id}-${addOn.attendee.id}-${ticket.classId}`,
        attendeeId: addOn.attendee.id,
        perSession: filterSessionsWithoutAvailableSessions(addOn.perSession).map((session) => {
          return {
            addonOptionId: session.addonOption.id,
            price: session.addonOption.price,
            selectedSessions:
              session.selectedSessions.length > 0
                ? getExistingSelectedSessions(session)
                : initialSessions(session),
            note: session.note,
            basketTicketItemId: ticket.id,
          };
        }),
        perBooking: addOn.perBooking?.map((booking) => {
          return {
            addonOptionId: booking.addonOption.id,
            price: booking.addonOption.price,
            quantity: booking.quantity,
            note: booking.note,
            basketTicketItemId: ticket.id,
          };
        }),
      };
    });
  });

  const addOnsForm = useForm<AddOnFormValues>({
    initialValues: {
      addOns: initialAddOnValues || [],
    },
  });

  const openSessionDetails = (
    session: PerSessionAddonList,
    addOnIndex: number,
    sessionIndex: number,
  ) => {
    setActiveSessionInfo({
      addOn: session,
      addOnIndex,
      sessionIndex,
    });

    open();
  };

  const closeSessionDetails = () => {
    setActiveSessionInfo(null);
    close();
  };

  const getTitle = (session?: PerSessionAddonList) => {
    if (!session) {
      return null;
    }

    const isFreeAddon = session.addonOption.price === 0;

    return (
      <Box ml={isMobile ? 0 : 'md'}>
        <Text c={theme.colors.blue[8]} fw={700} size="18px" mb="4px" mt={isMobile ? 'md' : 'lg'}>
          {session.addonOption.name}
        </Text>
        <Text c={theme.colors.gray[6]} fw={600} size="12px" mb="md">
          {session.addonOption.description}
        </Text>
        {isFreeAddon ? (
          <Text c={theme.colors.blue[8]} fw={600} size="14px" mb="md">
            <Text c={theme.colors.pink[6]} component="span">
              Free
            </Text>{' '}
            / session
          </Text>
        ) : (
          <Text c={theme.colors.blue[8]} fw={600} size="14px" mb="md">
            {formatPenceToPounds(session.addonOption.price)} / session
          </Text>
        )}
      </Box>
    );
  };

  const resetSelectedSessions = async (addOnIndex: number, sessionIndex: number) => {
    const updatedAddOns = addOnsForm.values.addOns?.map((addOn, aIndex) => {
      if (aIndex === addOnIndex) {
        const updatedPerSession = addOn.perSession.map((session, sIndex) => {
          if (sIndex === sessionIndex) {
            // Reset all selectedSessions to checked: false
            const updatedSelectedSessions = session.selectedSessions.map((selectedSession) => ({
              ...selectedSession,
              checked: false,
            }));
            return { ...session, selectedSessions: updatedSelectedSessions };
          }
          return session;
        });
        return { ...addOn, perSession: updatedPerSession };
      }
      return addOn;
    });

    addOnsForm.setValues((prevValues) => ({
      ...prevValues,
      addOns: updatedAddOns,
    }));
  };

  const addToOrder = async (addOnIndex: number, sessionIndex: number) => {
    const updateValues = addOnsForm?.values?.addOns?.[addOnIndex].perSession[
      sessionIndex
    ].selectedSessions.map((s) => ({ ...s, checked: true }));

    addOnsForm.setFieldValue(
      `addOns.${addOnIndex}.perSession.${sessionIndex}.selectedSessions`,
      updateValues,
    );
  };

  const handleNextClick = async () => {
    const inputData = getAddOnInput(addOnsForm.values.addOns);
    await handleUpdateAddOns(inputData);
    executeScroll(scrollRef);
    setActiveStep(StepperEnum.REVIEW);
  };

  if (basketMutationLoading) {
    return (
      <Center style={{ height: '60vh' }}>
        <Pebbles />
      </Center>
    );
  }
  return (
    <Box className={classes.addOnsStepWrapper}>
      <Flex align="center" mb="16px" ml="sm">
        <Title order={3} className={classes.title}>
          Add-Ons
        </Title>
        <Text fw={400} ml="xs" className={classes.optionalTitle}>
          (optional)
        </Text>
      </Flex>
      {classesObj?.map((classObj) => {
        const filteredTicketsByClass = tickets.filter((ticket) => ticket.classId === classObj.id);

        return (
          <Accordion
            key={classObj.id}
            mb="md"
            value={activeItem}
            onChange={(value) => setActiveItem(value as string)}
            chevron={<CaretDown weight="bold" size={15} color={theme.colors.blue[8]} />}
            classNames={{
              item: classes.accordionItem,
              content: classes.accordionContent,
              control: classes.accordionControl,
              chevron: classes.accordionChevron,
            }}
          >
            <Accordion.Item key={classObj.id} value={classObj.id}>
              <Accordion.Control>
                <Text fw={700} size={'xl'} c={theme.colors.blue[8]}>
                  {classObj.name}
                </Text>
              </Accordion.Control>
              {filteredTicketsByClass?.map((ticket, ticketIndex) => {
                return ticket.addons.map((addOn) => {
                  const addOnIndex = initialAddOnValues.findIndex(
                    (_addOn) =>
                      _addOn.uniqueId === `${ticket.id}-${addOn.attendee.id}-${ticket.classId}`,
                  );
                  return (
                    <Fragment key={`${ticket.id}-${addOn.attendee.id}-${ticket.classId}`}>
                      <Accordion.Panel>
                        <Stack gap={8} mb={32}>
                          <Text fw={600} size={'xs'} c={theme.colors.gray[6]}>
                            Selected sessions:
                          </Text>
                          <Stack>
                            <SelectedSessionsList
                              product={ticket.product}
                              basketTickets={filteredTicketsByClass}
                            />
                          </Stack>
                        </Stack>
                        <>
                          <Flex mb={12} align={'center'} gap={2}>
                            <Person size={20} weight="fill" color={theme.colors.blue[5]} />
                            <Text fw={700} size={'md'} c={theme.colors.blue[8]}>
                              {addOn.attendee.fullName}
                            </Text>
                          </Flex>
                          {filterSessionsWithoutAvailableSessions(addOn.perSession).map(
                            (session, sessionIndex) => {
                              const isEditMode = !noSessionsAreChecked(
                                addOnIndex,
                                sessionIndex,
                                addOnsForm.values.addOns,
                              );
                              const isSingleSessionAddOn = session.availableSessions?.length === 1;
                              const isFreeAddOn = session.addonOption.price === 0;
                              const totalAddOnPrice =
                                totalPrice(
                                  addOnIndex,
                                  sessionIndex,
                                  session,
                                  addOnsForm.values.addOns,
                                ) ?? 0;
                              const totalAddOnAmount =
                                totalAmountAdded(
                                  addOnIndex,
                                  sessionIndex,
                                  addOnsForm.values.addOns,
                                ) ?? 0;

                              return (
                                <Fragment key={session.addonOption.id}>
                                  <Card className={classes.addOnCard}>
                                    <Flex justify="space-between" align="center">
                                      <Group
                                        wrap="nowrap"
                                        gap="xs"
                                        justify="flex-start"
                                        className={classes.addOnNameWrapper}
                                      >
                                        <Text size="14px" fw={700} c={theme.colors.blue[8]}>
                                          {session.addonOption.name}
                                        </Text>
                                        {isEditMode && (
                                          <CheckCircle
                                            size={22}
                                            weight="fill"
                                            color={theme.colors.lime[8]}
                                          />
                                        )}
                                      </Group>

                                      {isEditMode && !isSingleSessionAddOn && (
                                        <Flex align="center">
                                          <Button
                                            onClick={() => {
                                              openSessionDetails(session, addOnIndex, sessionIndex);
                                              trackAction(Actions.EDIT_ADDON, {
                                                addon: 'Session addon',
                                              });
                                            }}
                                            mr="md"
                                            leftSection={<PencilSimple size={18} weight="fill" />}
                                            classNames={{
                                              root: classes.addButton,
                                              section: classes.leftSection,
                                            }}
                                          >
                                            Edit
                                          </Button>
                                          <ActionIcon
                                            title="delete add on"
                                            onClick={() => {
                                              resetSelectedSessions(addOnIndex, sessionIndex);
                                              trackAction(Actions.DELETE_ADDON, {
                                                addon: 'Session addon',
                                              });
                                            }}
                                            variant="transparent"
                                          >
                                            <Trash
                                              weight="fill"
                                              color={theme.colors.red[8]}
                                              size={18}
                                            />
                                          </ActionIcon>
                                        </Flex>
                                      )}
                                      {!isEditMode && !isSingleSessionAddOn && (
                                        <Button
                                          onClick={() => {
                                            if (!isSingleSessionAddOn) {
                                              openSessionDetails(session, addOnIndex, sessionIndex);
                                            }
                                            trackAction(Actions.ADD_ADDON);
                                          }}
                                          leftSection={<Plus size={18} weight="bold" />}
                                          classNames={{
                                            root: classes.addButton,
                                            section: classes.leftSection,
                                          }}
                                        >
                                          Add
                                        </Button>
                                      )}
                                      {isSingleSessionAddOn && !isEditMode && (
                                        <Button
                                          onClick={() => {
                                            addToOrder(addOnIndex, sessionIndex);
                                            trackAction(Actions.ADD_ADDON, {
                                              addon: 'Session addon',
                                            });
                                          }}
                                          leftSection={<Plus size={18} weight="bold" />}
                                          classNames={{
                                            root: classes.addButton,
                                            section: classes.leftSection,
                                          }}
                                        >
                                          Add
                                        </Button>
                                      )}
                                      {isSingleSessionAddOn && isEditMode && (
                                        <ActionIcon
                                          title="delete add on"
                                          onClick={() => {
                                            resetSelectedSessions(addOnIndex, sessionIndex);
                                            trackAction(Actions.DELETE_ADDON, {
                                              addon: 'Session addon',
                                            });
                                          }}
                                          variant="transparent"
                                        >
                                          <Trash
                                            weight="fill"
                                            color={theme.colors.red[8]}
                                            size={18}
                                          />
                                        </ActionIcon>
                                      )}
                                    </Flex>
                                    <Text className={classes.description}>
                                      {session.addonOption.description}
                                    </Text>
                                    <Flex justify="space-between" align="center">
                                      <Text mt="xs" className={classes.price} fw={700}>
                                        {isFreeAddOn ? (
                                          <Text
                                            component="span"
                                            size="sm"
                                            fw={700}
                                            c={theme.colors.pink[6]}
                                          >
                                            Free
                                          </Text>
                                        ) : (
                                          formatPenceToPounds(session.addonOption.price)
                                        )}{' '}
                                        / session
                                        {totalAddOnAmount > 0 && (
                                          <Text size="sm" fw={700} component="span">
                                            {` x ${totalAddOnAmount}`}
                                          </Text>
                                        )}
                                      </Text>
                                      {isEditMode && totalAddOnPrice > 0 && (
                                        <Text size="14px" fw={700} c={theme.colors.blue[8]} mt="xs">
                                          Total {formatPenceToPounds(totalAddOnPrice)}
                                        </Text>
                                      )}
                                    </Flex>
                                    {isEditMode && (
                                      <>
                                        <Divider mt="md" variant="dashed" />
                                        <Textarea
                                          classNames={{ input: classes.textAreaInput }}
                                          radius="8px"
                                          mt="sm"
                                          mb="4px"
                                          autosize={true}
                                          maxRows={2}
                                          maxLength={65}
                                          onBlur={() =>
                                            trackAction(Actions.ADDON_ORDER_NOTES, {
                                              addon: 'Session addon',
                                            })
                                          }
                                          placeholder="Order notes (optional)"
                                          {...addOnsForm.getInputProps(
                                            `addOns.${addOnIndex}.perSession.${sessionIndex}.note`,
                                          )}
                                        />
                                      </>
                                    )}
                                  </Card>
                                </Fragment>
                              );
                            },
                          )}
                          {addOn?.perBooking && addOn.perBooking.length > 0 && (
                            <BookingAddons
                              addOn={addOn}
                              addOnIndex={addOnIndex}
                              addOnsForm={addOnsForm}
                            />
                          )}
                        </>
                        {filteredTicketsByClass.length > 1 &&
                          ticketIndex < filteredTicketsByClass.length - 1 && (
                            <Divider size="sm" variant="dashed" mt={32} mb={16} px={16} />
                          )}
                      </Accordion.Panel>
                    </Fragment>
                  );
                });
              })}
            </Accordion.Item>
          </Accordion>
        );
      })}
      {isMobile ? (
        <Drawer
          title={getTitle(activeSessionInfo?.addOn)}
          position="bottom"
          size="75%"
          opened={opened}
          onClose={() => {
            close();
          }}
          classNames={{
            content: classes.content,
            header: classes.header,
            close: classes.close,
            body: classes.body,
          }}
        >
          {activeSessionInfo && (
            <SessionSelectContent
              session={activeSessionInfo.addOn}
              addOnIndex={activeSessionInfo.addOnIndex}
              sessionIndex={activeSessionInfo.sessionIndex}
              addOnsForm={addOnsForm}
              handleUpdateAddOns={handleUpdateAddOns}
              closeSessionDetails={closeSessionDetails}
              hideEndTimes={hideEndTimes}
            />
          )}
        </Drawer>
      ) : (
        <Modal
          title={getTitle(activeSessionInfo?.addOn)}
          opened={opened}
          onClose={() => {
            close();
          }}
          classNames={{
            content: classes.content,
            header: classes.header,
            close: classes.close,
            body: classes.body,
          }}
          centered
          size={501}
          withCloseButton={false}
        >
          <CloseButton
            size="md"
            icon={<X size={16} weight="bold" />}
            className={classes.closeIcon}
            pos="absolute"
            top={isMobile ? 18 : 16}
            right={isMobile ? 16 : 16}
            onClick={close}
          />
          {activeSessionInfo && (
            <SessionSelectContent
              session={activeSessionInfo.addOn}
              addOnIndex={activeSessionInfo.addOnIndex}
              sessionIndex={activeSessionInfo.sessionIndex}
              addOnsForm={addOnsForm}
              handleUpdateAddOns={handleUpdateAddOns}
              closeSessionDetails={closeSessionDetails}
              hideEndTimes={hideEndTimes}
            />
          )}
        </Modal>
      )}
      <Box className={classes.nextButtonWrapper}>
        <Button onClick={handleNextClick} className={classes.nextButton} fullWidth>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default AddOnsStep;
