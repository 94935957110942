import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Text,
  Badge,
  Notification,
  Flex,
  useMantineTheme,
  Combobox,
  InputBase,
  Input,
  useCombobox,
  Button,
  Stack,
  Group,
  Modal,
} from '@mantine/core';
import DaysOfWeekDisplayLine from 'components/ui/DaysOfWeekDisplayLine/DaysOfWeekDisplayLine';
import TimesAndAvailability from 'components/ActivityDetails/TimesAndAvailability/InstantBookTimesAndAvailability';
import classes from './SessionSelect.module.scss';
import {
  getBasketInput,
  getSubscriptionBasketInput,
  getTimeRanges,
  handleRouting,
} from 'components/ActivityDetails/ActivityDetails.utils';
import {
  ActivityDetails as ActivityDetailsType,
  Product,
  CreateBasketInput,
  SelectedTickets,
  BasketSchedule,
} from 'types';
import { ActivityTicket } from 'interfaces';
import { SocialProofLabelEnum, ActivityTypeEnum, ActivityBlockSubtypeEnum } from 'enums';
import { useBasket } from 'context/BasketContext';
import { useMediaQuery } from '@mantine/hooks';
import { UtmParamsData } from 'components/ActivityDetails/ActivityDetails';
import { useMutation } from '@apollo/client';
import { CreateBasketMutation } from 'graphql/mutations';
import { useRouter } from 'next/router';
import { Actions, trackAction } from 'utils/amplitude';
import TicketTypesSelectionModal from 'components/TicketTypesSelectionModal/TicketTypesSelectionModal';
import CreateAccountModal from 'components/CreateAccountModal/CreateAccountModal';
import { CalendarBlank, CaretDown, CaretUp, Clock, Info, Ticket } from '@phosphor-icons/react';
import SubsTimesAndAvailability from 'components/ActivityDetails/TimesAndAvailability/SubsTimesAndAvailability';
import classNames from 'classnames';
import GetTicketsButton from 'components/GetTicketsButton/GetTicketsButton';

interface ISessionSelectProps {
  activityDetails: ActivityDetailsType;
  handleGetTickets: (session: Product) => void;
  socialProofBadge: SocialProofLabelEnum | null;
  selectedSession: Product | null;
  setSelectedSession(val: Product | null): void;
  setUserSelectedRadio?(val: boolean): void;
  blockBookingTabActive: boolean;
  setBlockBookingTabActive: React.Dispatch<React.SetStateAction<boolean>>;
  socialProofBannerTitleEventProperty?: () => string | null;
  utmParams?: UtmParamsData;
  opened: boolean;
  open: () => void;
  close: () => void;
}

interface IClassesOptionsProps {
  name: string;
  dateRange: string;
  timeRanges: string[];
  classOptionIndex: number;
}

const ClassesOptions: React.FC<IClassesOptionsProps> = ({
  name,
  dateRange,
  timeRanges,
  classOptionIndex,
}) => {
  const theme = useMantineTheme();
  const moreTimesCount = timeRanges.length - 4;
  return (
    <Stack
      gap={12}
      className={classes.classesOptions}
      data-testId={`class-option-${classOptionIndex + 1}`}
    >
      <Text c={theme.colors.blue[8]} size="md" fw={600}>
        {name}
      </Text>
      <Flex align={'center'} gap={8}>
        <CalendarBlank size={20} color={theme.colors.blue[8]} />
        <Text c={theme.colors.blue[8]} size="md" fw={600}>
          {dateRange}
        </Text>
      </Flex>
      <Group gap={12}>
        {timeRanges.map((time, index) => {
          if (index < 3 || (index === 3 && timeRanges.length === 4)) {
            return (
              <Flex gap={8} key={index}>
                <Clock size={20} color={theme.colors.gray[6]} />
                <Text c={theme.colors.gray[6]} fw={600} size={'sm'}>
                  {time}
                </Text>
              </Flex>
            );
          }
        })}
        {timeRanges.length > 4 && (
          <Text c={theme.colors.gray[6]} fw={600} size={'sm'}>
            + {moreTimesCount} more time{`${moreTimesCount === 1 ? '' : 's'}`}{' '}
          </Text>
        )}
      </Group>
    </Stack>
  );
};

const SessionSelect: React.FC<ISessionSelectProps> = ({
  activityDetails,
  handleGetTickets,
  socialProofBadge,
  selectedSession,
  setSelectedSession,
  setUserSelectedRadio,
  blockBookingTabActive,
  setBlockBookingTabActive,
  socialProofBannerTitleEventProperty,
  utmParams = {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
  },
  open,
  opened,
  close,
}) => {
  const theme = useMantineTheme();
  const Router = useRouter();
  const isMobile = useMediaQuery('(max-width: 768px)', true);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const {
    basketItems,
    setBasketItems,
    selectedTickets,
    setSelectedTickets,
    showTicketTypesModal,
    setShowTicketTypesModal,
    basketActivityTickets,
    userToken,
    showCreateAccountModal,
    setShowCreateAccountModal,
    selectedClass,
    setSelectedClass,
    setBasketSchedules,
    basketSchedules,
    setBasketAccordionValue,
    animateBasketDrawer,
  } = useBasket();

  const { activity, classes: classesObj } = activityDetails;
  const { push: routerPush } = Router;

  const isSubscription = activity.activityType === ActivityTypeEnum.SUBSCRIPTION;
  const isSingleClass = classesObj.length === 1;

  const [showSelectionError, setShowSelectionError] = useState<boolean>(false);
  const [showSessionSelect, setShowSessionSelect] = useState<boolean>(
    isSubscription || isSingleClass,
  );

  useEffect(() => {
    setSelectedTickets([]);
  }, [basketActivityTickets, setSelectedTickets]);

  useEffect(() => {
    if (classesObj.length === 1) {
      setSelectedClass(classesObj[0]);
    }
  }, [classesObj, setSelectedClass]);

  const someDatesSoldOut = useMemo(() => {
    if (isSubscription) {
      return false;
    }
    const { blocks, sessions } = activityDetails.classes[0];

    const soldOutBlock = activity.allowBlockBookings
      ? blocks.some((booking) =>
          booking.allBlockDates?.some((date) => date.spotsLeft === 0 && !date.isInPast),
        )
      : false;

    const soldOutSingle = activity.allowIndividualBookings
      ? sessions.some((session) => session.products?.some((product) => product.spotsLeft === 0))
      : false;

    return soldOutBlock || soldOutSingle;
  }, [
    activityDetails,
    activity.allowBlockBookings,
    activity.allowIndividualBookings,
    isSubscription,
  ]);

  const showTicketModal = useMemo<boolean>(() => {
    const hasSelection = Boolean(selectedSession) || Boolean(basketItems[0]);
    return Boolean(hasSelection && showTicketTypesModal);
  }, [selectedSession, basketItems, showTicketTypesModal]);

  const [basketCreate, { loading }] = useMutation(CreateBasketMutation, {
    onError: (error) => {
      console.log(error);
    },
  });

  const createBasketMutation = async (input: CreateBasketInput): Promise<string> => {
    const { data } = await basketCreate({
      variables: {
        input,
      },
      ...(userToken && {
        context: {
          headers: {
            Authorization: `${userToken}`,
          },
        },
      }),
    });

    return data.createBasket.id;
  };

  // Calls create basket with subscription input and redirects to checkout details or account modal pop up
  const handleSubscriptionBasketCall = async (routeName?: string, ticketId?: string) => {
    const basketInput = getSubscriptionBasketInput(
      selectedSession,
      activityDetails.activity.id,
      utmParams,
      ticketId,
    );

    if (basketInput) {
      const basketId = await createBasketMutation(basketInput);

      handleRouting(basketId, routerPush, routeName);
    }

    setShowTicketTypesModal(false);
    setShowCreateAccountModal(false);
  };

  //  Calls create basket with block/indiv basket inputs and redirects to checkout details or account modal pop up
  const handleStandardBasketCall = async (routeName?: string) => {
    const basketInput = getBasketInput(activity.id, basketSchedules, utmParams);
    const basketId = await createBasketMutation(basketInput);

    handleRouting(basketId, routerPush, routeName);

    setShowTicketTypesModal(false);
    setShowCreateAccountModal(false);
  };

  // Called on subs book now button in ticket modal, sets tickets. Opens account modal if not logged in or redirects to checkout details
  const handleSubscriptionBookNow = async (subsTicket?: ActivityTicket) => {
    if (subsTicket) {
      setSelectedTickets([
        {
          ticketId: subsTicket.id,
          name: subsTicket.name,
          ticketPrice: subsTicket.price,
          capacity: subsTicket.capacity,
          pricingPolicy: subsTicket.pricingPolicy,
        },
      ]);
    }

    if (!userToken) {
      setShowCreateAccountModal(true);
    } else {
      await handleSubscriptionBasketCall(undefined, subsTicket?.id);
    }

    trackAction(Actions.BOOK_BASKET, {
      activityLocation: activityDetails?.activity.isOnline ? 'online' : 'in-person',
      activityType: activityDetails?.activity.activityType,
      ...(socialProofBadge && {
        socialProofBadge,
      }),
      ...(socialProofBannerTitleEventProperty && {
        socialProofBanner: socialProofBannerTitleEventProperty(),
      }),
    });
  };

  // Builds basketSchedules state when clicking add to basket
  const handleAddToBasket = async (tickets: SelectedTickets[]) => {
    if (tickets.length === 0) return;

    const getSessions = () => {
      if (basketItems.length > 0) {
        return basketItems.filter((session): session is Product => session !== null);
      }
      if (selectedSession) {
        return [selectedSession];
      }
      return [];
    };

    const sessions = getSessions();

    const newSchedules: BasketSchedule[] = tickets.map((ticket) => ({
      ticket: ticket,
      selectedSessions: sessions,
      className: selectedClass?.name,
    }));

    if (selectedSession?.checkoutKey !== 'block') {
      setSelectedSession(null);
    }

    setBasketSchedules((prev) => [...newSchedules, ...prev]);
    setSelectedTickets([]);
    setBasketItems([]);
    setShowTicketTypesModal(false);
    setBasketAccordionValue(null);
    animateBasketDrawer();
  };

  // Handles redirection and basket calls from create account modal
  const handleCreateAccountModalClickWrapper = async (routeName: string, ticketId?: string) => {
    if (isSubscription) {
      await handleSubscriptionBasketCall(routeName, ticketId);
    } else {
      await handleStandardBasketCall(routeName);
    }
  };

  const filteredClassesObj = classesObj.filter((classObj) => classObj.dateRange);

  const options = filteredClassesObj.map(
    ({ name, id, blocks, sessions, dateRange }, classOptionIndex) => {
      const timeRanges = getTimeRanges(blocks, sessions, activity.hideEndTimes) as string[];

      if (isMobile) {
        return (
          <Box
            key={id}
            mb={12}
            onClick={() => {
              setSelectedClass(classesObj.filter((singleClass) => singleClass.id === id)[0]);
              setShowSessionSelect(true);
              setBasketItems([]);
              close();
            }}
          >
            <ClassesOptions
              name={name}
              dateRange={dateRange}
              timeRanges={timeRanges}
              classOptionIndex={classOptionIndex}
            />
          </Box>
        );
      }

      return (
        <React.Fragment key={id}>
          <Combobox.Option value={id} key={id} className={classes.classSelect}>
            <ClassesOptions
              name={name}
              dateRange={dateRange}
              timeRanges={timeRanges}
              classOptionIndex={classOptionIndex}
            />
          </Combobox.Option>
          <div className={classes.dotted}></div>
        </React.Fragment>
      );
    },
  );

  return (
    <>
      <Flex direction={'column'} mx={0}>
        {!isMobile && activity.activityType !== ActivityTypeEnum.SUBSCRIPTION && !isSingleClass && (
          <>
            <Combobox
              store={combobox}
              onOptionSubmit={(val) => {
                setSelectedClass(classesObj.filter((singleClass) => singleClass.id === val)[0]);
                setShowSelectionError(false);
                setShowSessionSelect(true);
                combobox.closeDropdown();
                //   Reset basket context state on unmount
                setBasketItems([]);
              }}
            >
              <Combobox.Target>
                <InputBase
                  label={
                    <Flex align={'center'} gap={8} mb={12} fs="lg">
                      <Text fw={700} c={theme.colors.blue[8]}>
                        Make a booking
                      </Text>
                    </Flex>
                  }
                  component="button"
                  type="button"
                  pointer
                  rightSection={
                    combobox.dropdownOpened ? (
                      <CaretUp size={16} color={theme.colors.blue[8]} />
                    ) : (
                      <CaretDown size={16} color={theme.colors.blue[8]} />
                    )
                  }
                  rightSectionPointerEvents="none"
                  onClick={() => combobox.toggleDropdown()}
                  classNames={{
                    input: classNames(classes.bookingSelectInput, {
                      [classes.bookingSelectInputError]: showSelectionError,
                    }),
                    section: classes.bookingSelectSection,
                  }}
                  data-testid="please-select-a-class"
                >
                  {selectedClass ? (
                    <Text c={theme.colors.blue[8]} fw={600} size="md" truncate="end">
                      {selectedClass.name}
                    </Text>
                  ) : (
                    <Input.Placeholder className={classes.classSelectPlaceholder}>
                      Please select a class
                    </Input.Placeholder>
                  )}
                </InputBase>
              </Combobox.Target>

              <Combobox.Dropdown className={classes.dropdown}>
                <Combobox.Options>{options}</Combobox.Options>
              </Combobox.Dropdown>
            </Combobox>
            {showSelectionError && (
              <Text c={theme.colors.red[6]} size="sm" mb="md">
                Please select a class
              </Text>
            )}
          </>
        )}
        {isMobile && activity.activityType !== ActivityTypeEnum.SUBSCRIPTION && !isSingleClass && (
          <>
            <InputBase
              label={
                <Flex align={'center'} gap={8} mb={12} fs="lg">
                  <Text fw={700} c={theme.colors.blue[8]}>
                    Make a booking
                  </Text>
                </Flex>
              }
              component="button"
              type="button"
              pointer
              rightSection={
                combobox.dropdownOpened ? (
                  <CaretUp size={16} color={theme.colors.blue[8]} />
                ) : (
                  <CaretDown size={16} color={theme.colors.blue[8]} />
                )
              }
              rightSectionPointerEvents="none"
              onClick={open}
              classNames={{
                input: classNames(classes.bookingSelectInput, {
                  [classes.bookingSelectInputError]: showSelectionError,
                }),
                section: classes.bookingSelectSection,
              }}
            >
              {selectedClass ? (
                <Text c={theme.colors.blue[8]} fw={600} size="md" truncate="end">
                  {selectedClass.name}
                </Text>
              ) : (
                <Input.Placeholder className={classes.classSelectPlaceholder}>
                  Please select a class
                </Input.Placeholder>
              )}
            </InputBase>
            {showSelectionError && (
              <Text c={theme.colors.red[6]} size="sm" mb="md">
                Please select a class
              </Text>
            )}
          </>
        )}
        {showSessionSelect && (selectedClass || isSubscription) && (
          <>
            <Box>
              {(selectedClass?.dateRange ||
                activity.activityType === ActivityTypeEnum.SUBSCRIPTION) && (
                <Box className={classes.detailsItemDateRange}>
                  <CalendarBlank size={20} color={theme.colors.blue[8]} />
                  <Text component="p" c={theme.colors.blue[8]} fw={600} size="md">
                    {activity.activityType === ActivityTypeEnum.SUBSCRIPTION
                      ? 'Weekly sessions'
                      : selectedClass?.dateRange}
                  </Text>
                </Box>
              )}
              {activity.activityType !== ActivityTypeEnum.ONE_OFF && (
                <Box className={classes.daysOfWeekDisplayLine}>
                  <DaysOfWeekDisplayLine
                    weekdays={selectedClass?.weekdays || activityDetails.weekdays || []}
                    selectedLimeBg
                    fullWidth
                    enlarged
                  />
                </Box>
              )}
              {someDatesSoldOut && (
                <Badge className={classes.someDatesSoldOut}>
                  {!activityDetails.anySpotsLeft ? 'All dates sold out' : 'Some dates sold out'}
                </Badge>
              )}
              {activity?.blockSubtype === ActivityBlockSubtypeEnum.ALL_DAYS && (
                <Notification
                  withCloseButton={false}
                  radius={0}
                  classNames={{
                    icon: classes.notificationIcon,
                    root: classes.notificationRoot,
                    title: classes.notificationTitle,
                  }}
                  icon={<Info size={26} color={theme.colors.blue[8]} />}
                  title={
                    selectedClass?.sessions.length === 0
                      ? 'Tickets will be booked for all dates listed.'
                      : 'Tickets will be booked for all dates listed \n or you can book a single session in the Single tab.'
                          .split('\n')
                          .map((paragraph, key) => <div key={key}>{paragraph}</div>)
                  }
                />
              )}
            </Box>
            <Flex direction={'column'} align={'center'} mb={12}>
              <Box
                component="section"
                aria-label="times and availability"
                id="times-and-availability"
                className={(classes.anchorTag, classes.card)}
              >
                {selectedClass && !isSubscription ? (
                  <TimesAndAvailability
                    activityDetails={activityDetails}
                    selectedClass={selectedClass}
                    blockBookingTabActive={blockBookingTabActive}
                    setBlockBookingTabActive={setBlockBookingTabActive}
                    selectedSession={selectedSession}
                    setSelectedSession={setSelectedSession}
                    setUserSelectedRadio={setUserSelectedRadio}
                  />
                ) : (
                  <SubsTimesAndAvailability
                    activityDetails={activityDetails}
                    handleGetTickets={handleGetTickets}
                    selectedSession={selectedSession}
                    setSelectedSession={setSelectedSession}
                    setUserSelectedRadio={setUserSelectedRadio}
                  />
                )}
              </Box>
            </Flex>
          </>
        )}
      </Flex>
      {!showSessionSelect && !selectedSession && (
        <Button
          mt={12}
          mb={18}
          className={classes.chooseTicketsBtn}
          leftSection={<Ticket size={16} />}
          onClick={() => {
            if (!selectedClass) {
              return setShowSelectionError(true);
            }
            setShowSessionSelect(true);
          }}
          fullWidth={true}
        >
          Choose tickets
        </Button>
      )}
      {selectedSession && selectedSession?.spotsLeft > 0 && !isSubscription && (
        <GetTicketsButton
          disabled={!blockBookingTabActive && basketItems.length === 0}
          mt={isMobile ? 0 : 'md'}
          mb="md"
          fullWidth
          radius="xl"
          size="sm"
          onClick={() => {
            if (!selectedSession) {
              return;
            }

            handleGetTickets(selectedSession);

            trackAction(Actions.GET_TICKETS, {
              activityLocation: activity.isOnline ? 'online' : 'in-person',
              activityType: activity.activityType,
              ...(socialProofBadge && {
                socialProofBadge,
              }),
              ...(socialProofBannerTitleEventProperty && {
                socialProofBanner: socialProofBannerTitleEventProperty(),
              }),
            });
          }}
        >
          Choose tickets
        </GetTicketsButton>
      )}
      <Modal
        opened={opened}
        onClose={close}
        title={activity.name}
        fullScreen={true}
        bg={'#F7F7F7'}
        classNames={{
          content: classes.modalContent,
          header: classes.modalHeader,
          title: classes.modalTitle,
          body: classes.modalBody,
        }}
      >
        <Text size="lg" fw={600} c={theme.colors.gray[6]} mb="xs">
          Select a class
        </Text>
        {options}
        <Text c={theme.colors.gray[5]} size="sm" fw={600} mt={12} ta="center">
          You have reached the end of the list
        </Text>
      </Modal>
      {showTicketModal && basketActivityTickets && (
        <TicketTypesSelectionModal
          supplierId={activity.supplier.id}
          data-cy="modal"
          opened={showTicketTypesModal || false}
          onClose={() => {
            setShowTicketTypesModal(false);
            setSelectedTickets([]);
          }}
          withCloseButton={false}
          name={activityDetails?.activity.name}
          selectedSession={selectedSession}
          selectedTickets={selectedTickets || []}
          setSelectedTickets={setSelectedTickets}
          handleAddToBasket={handleAddToBasket}
          handleSubscriptionBookNow={handleSubscriptionBookNow}
          loading={loading}
          activityType={activityDetails?.activity.activityType}
          tickets={basketActivityTickets || []}
          fromBasket={selectedSession?.checkoutKey !== 'block' && Boolean(basketItems[0])}
          socialProofBadge={socialProofBadge}
          activityId={activity.id}
          supplierName={activity.supplier.name}
        />
      )}
      <CreateAccountModal
        data-cy="modal"
        opened={showCreateAccountModal || false}
        onClose={() => setShowCreateAccountModal(false)}
        withCloseButton={false}
        handleCreateAccountModalClick={handleCreateAccountModalClickWrapper}
      />
    </>
  );
};

export default SessionSelect;
