import { getClaimListingLink } from '@pebble/common';
import {
  Box,
  Grid,
  Group,
  Title,
  Text,
  Container,
  Spoiler,
  Image,
  Flex,
  ActionIcon,
  Center,
  Badge,
  Notification,
  Button,
  useMantineTheme,
} from '@mantine/core';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ChildFace } from '@icons';
import buildFullAddressFromLocation, {
  AddressFieldsType,
} from 'utils/buildFullAddressFromLocation';
import { ActivityDetails as ActivityDetailsType, CreateBasketInput, Product } from 'types';
import { ActivityTypeEnum, SocialProofLabelEnum, ActivityBookingTypeEnum } from 'enums';
import ActivityMap from 'components/ActivityMap/ActivityMap';
import BackButton from 'components/BackButton/BackButton';
import { CloudinaryImage } from 'components/ui';
import Link from 'next/link';
import { trackAction, Actions } from 'utils/amplitude';
import { useDisclosure, useMediaQuery, useScrollIntoView, useSessionStorage } from '@mantine/hooks';
import ShareButton from 'components/ui/ShareButton/ShareButton';
import { ActivityBreadcrumbs, RatingsBar, ReviewsWidget } from 'components/index';
import { useMemo } from 'react';
import {
  getBasketInput,
  getSocialProofBannerTitle,
  getTicketPriceSummary,
  handleRouting,
} from 'components/ActivityDetails/ActivityDetails.utils';
import { VerifiedButton, VerifiedModal } from 'components/VerifiedModal';
import ImageCarousel from './ImageCarousel/ImageCarousel';
import Pebbles from '../../../../../shared/ui/Pebbles/Pebbles';
import SocialLinksBanner from 'components/SocialLinksBanner/SocialLinksBanner';
import CategoryIcon from 'components/ui/CategoryIcon/CategoryIcon';
import { useBasket } from 'context/BasketContext';
import getSocialProofBadge from 'utils/getSocialProofBadge';
import LeavingModal from './LeavingModal/LeavingModal';
import { getBreadcrumbItems } from 'pages/activity/[slug]/utils/getBreadcrumbItems';
import SessionSelect from 'components/SessionSelect/SessionSelect';
import classes from './ActivityDetails.module.scss';
import classNames from 'classnames';
import SubsSchedule from './SubsSchedule/SubsSchedule';
import { CaretRight, Info, MapPin, Ticket, VideoCamera } from '@phosphor-icons/react';
import { ActivityTicket } from 'interfaces';
import ScheduleBasket from './ScheduleBasket/ScheduleBasket';
import { useMutation } from '@apollo/client';
import { CreateBasketMutation } from 'graphql/mutations';
import { useRouter } from 'next/router';

interface ActivityDetailsProps {
  activityDetails: ActivityDetailsType;
  goBack: () => void;
  redirectToCheckout: (basketId: string) => void;
  activityId: string | undefined;
  userToken: string | null;
  hasReviews: boolean;
  loading: boolean;
  isChromeIncognito?: boolean;
}

export interface UtmParamsData {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
}

const addressFields: AddressFieldsType = ['addressLine1', 'addressLine2', 'city', 'postCode'];

export const GetUtmParams = ({
  setUtmParams,
  isChromeIncognito,
}: {
  setUtmParams: Dispatch<SetStateAction<UtmParamsData>>;
  isChromeIncognito: boolean;
}) => {
  const [utmParams] = useSessionStorage<UtmParamsData>({
    key: 'pebble_utm_params',
  });

  useEffect(() => {
    if (isChromeIncognito) return;

    setUtmParams(utmParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utmParams, isChromeIncognito]);

  return <></>;
};

const ActivityDetails: React.FC<ActivityDetailsProps> = (props) => {
  const { activityDetails, goBack, userToken, hasReviews, isChromeIncognito, loading } = props;

  const {
    activity,
    totalActivityBookings,
    lastBookingTime,
    anySpotsLeft,
    weekdays,
    subscriptions,
  } = activityDetails;

  const { supplier } = activity;
  const isSubscription = activity?.activityType === ActivityTypeEnum.SUBSCRIPTION;
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  const Router = useRouter();
  const { push: routerPush } = Router;

  const { scrollIntoView: scrollToSessionSelect, targetRef: sessionSelectRef } =
    useScrollIntoView<HTMLDivElement>({
      duration: 750,
      offset: 96,
    });

  const { scrollIntoView: scrollToMap, targetRef: mapRef } = useScrollIntoView<HTMLDivElement>({
    duration: 750,
    offset: 96,
  });

  const { scrollIntoView: scrollToReviews, targetRef: reviewsRef } =
    useScrollIntoView<HTMLDivElement>({ duration: 750, offset: 96 });

  const {
    setActivityDetails,
    setUserToken,
    setBasketItems,
    setBasketActivityTickets,
    basketItems,
    setSelectedTickets,
    setShowTicketTypesModal,
    selectedSession,
    setSelectedSession,
    selectedClass,
    setBasketSchedules,
    setShowCreateAccountModal,
    basketSchedules,
    setSelectedClass,
    setBasketAccordionValue,
  } = useBasket();

  const [showVerifiedModal, setShowVerifiedModal] = useState<boolean>(false);
  const [userSelectedRadio, setUserSelectedRadio] = useState(false);
  const [openLeavingModal, setOpenLeavingModal] = useState(false);
  const [openSubsScheduleModal, setOpenSubsScheduleModal] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [blockBookingTabActive, setBlockBookingTabActive] = useState<boolean>(
    activity.allowBlockBookings,
  );
  const [utmParams, setUtmParams] = useState<UtmParamsData>({
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
  });

  const hasSocialProof =
    activity.socialProof.justAdded ||
    activity.socialProof.limitedSpots ||
    activity.socialProof.popular;

  const allTickets: ActivityTicket[] = useMemo(() => {
    if (isSubscription) {
      return [...subscriptions?.tickets];
    } else if (selectedClass) {
      return [...selectedClass?.tickets?.block, ...selectedClass?.tickets?.individual];
    } else {
      // Iterate through each class in activityDetails.classes
      return activityDetails.classes.flatMap(({ tickets: allClassesTickets }) => {
        return [...allClassesTickets?.block, ...allClassesTickets?.individual];
      });
    }
  }, [isSubscription, subscriptions?.tickets, selectedClass, activityDetails]);

  const isSoldOut = !anySpotsLeft || allTickets.every((ticket) => !ticket.isAvailable);

  const socialProofBadge = useMemo(() => {
    if (isSoldOut || hasSocialProof) {
      return getSocialProofBadge(activity.socialProof, isSoldOut);
    } else {
      return null;
    }
  }, [activity.socialProof, isSoldOut, hasSocialProof]);

  const showReviewsWidgets = supplier?.reviewsEnabled && hasReviews;

  const breadcrumbs = useMemo(() => {
    // TODO: add franchise once returned by API
    return getBreadcrumbItems({
      activity: activityDetails.activity,
      supplier: activityDetails.activity.supplier,
      franchise: activityDetails.activity.supplier.franchise,
    });
  }, [activityDetails.activity]);

  const handleGetTickets = (session: Product) => {
    setSelectedSession(session);
    setShowTicketTypesModal(true);
  };

  const getSingleImage = () => {
    if (activity.cloudinaryImageId) {
      return (
        <section className={classes.cardHeader} aria-label="activity header">
          <div className={classes.imageWrapper}>
            <CloudinaryImage
              id={activity?.cloudinaryImageId}
              alt={`${activity.name} - ${supplier.name}`}
              isActivityDetails={true}
            />
          </div>
        </section>
      );
    } else {
      return (
        <Image
          src={
            isMobile
              ? '../../pebble_placeholder_image.png'
              : '../../../../pebble_placeholder_activity_details.png'
          }
          width="100%"
          height="100%"
          fit="contain"
          alt="Patterns with shapes and lines"
        />
      );
    }
  };

  // Update basket context with activity details
  useEffect(() => {
    if (userToken) setUserToken(userToken);

    setActivityDetails(activityDetails);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityDetails, userToken]);

  // Reset basket context state on unmount
  useEffect(() => {
    return () => {
      setBasketItems([]);
      setSelectedClass(null);
      setActivityDetails(null);
      setSelectedTickets([]);
      setBasketSchedules([]);
      setBasketAccordionValue(null);
      setSelectedSession(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSubscription) {
      setBasketActivityTickets(activityDetails.subscriptions.tickets);
      return;
    }
    if (selectedClass) {
      setBasketActivityTickets(
        blockBookingTabActive ? selectedClass.tickets.block : selectedClass.tickets.individual,
      );

      return;
    }
  }, [
    isSubscription,
    selectedClass,
    blockBookingTabActive,
    activityDetails.classes,
    activityDetails.subscriptions,
    setBasketActivityTickets,
  ]);

  const [basketCreate] = useMutation(CreateBasketMutation, {
    onError: (error) => {
      console.log(error);
    },
  });

  const createBasketMutation = async (input: CreateBasketInput): Promise<string> => {
    const { data } = await basketCreate({
      variables: {
        input,
      },
      ...(userToken && {
        context: {
          headers: {
            Authorization: `${userToken}`,
          },
        },
      }),
    });

    return data?.createBasket.id;
  };

  const handleCheckoutButton = async () => {
    if (!userToken) {
      setShowCreateAccountModal(true);
    } else {
      const basketInput = getBasketInput(activity.id, basketSchedules, utmParams);
      const basketId = await createBasketMutation(basketInput);

      handleRouting(basketId, routerPush);

      setShowTicketTypesModal(false);
      setShowCreateAccountModal(false);
    }
  };

  const checkBasketItemsSpotsLeft = () => {
    if (basketItems.length === 0) return false;
    return basketItems.some((item) => item.spotsLeft === 1);
  };

  const socialProofBannerTitleEventProperty = () => {
    if (
      getSocialProofBannerTitle(lastBookingTime, totalActivityBookings)?.includes('Last booked')
    ) {
      return 'Last booked banner';
    } else if (
      getSocialProofBannerTitle(lastBookingTime, totalActivityBookings)?.includes('In demand')
    ) {
      return 'Join others banner';
    } else {
      return null;
    }
  };

  const formatWeekdays = weekdays?.map((d) => d.slice(0, -1));

  return (
    <>
      {loading ? (
        <Center style={{ height: '50vh' }}>
          <Pebbles />
        </Center>
      ) : (
        <Container mt="lg" pb={!isSubscription ? 84 : 0} className={classes.container}>
          <GetUtmParams
            setUtmParams={setUtmParams}
            isChromeIncognito={isChromeIncognito || false}
          />
          <Grid gutter="md" columns={12}>
            <Grid.Col span={12} py={0}>
              <Box className={classes.pageSection}>
                <BackButton
                  onClick={() => {
                    trackAction(Actions.BACK_DETAILS);
                    if (userSelectedRadio || basketItems.length > 0) {
                      setOpenLeavingModal(true);
                    } else {
                      goBack();
                    }
                  }}
                />
              </Box>
            </Grid.Col>
            <Flex w="100%" justify="space-between" mx="sm" pl={isMobile ? 'sm' : undefined}>
              {/** The onClick is a callback because useRouter is undefined in test env and it's a pain to mock. */}
              <ActivityBreadcrumbs items={breadcrumbs} />
              {!isMobile && <ShareButton activityName={activity?.name} />}
            </Flex>
            {!isMobile && (
              <Grid.Col span={12} pb="0">
                <Box
                  component="section"
                  className={classes.activityHeader}
                  aria-label="activity header"
                >
                  <Title mb="xs" order={1}>
                    {activity?.seoPageTitle}
                  </Title>
                  <Flex direction="row" align="baseline" wrap={isMobile ? 'wrap' : 'nowrap'}>
                    {showReviewsWidgets && (
                      <Box mr="sm">
                        <RatingsBar
                          supplierId={supplier.id}
                          onClick={() => {
                            scrollToReviews();
                            trackAction(Actions.REVIEWS_STARS_CLICK_ADP);
                          }}
                          mode="minimal"
                          hideText
                        />
                      </Box>
                    )}
                    <Link href={`/supplier/${supplier.slug}`} passHref legacyBehavior>
                      <Button
                        data-cy="supplierLink"
                        component="a"
                        className={classes.supplierDetailsButton}
                        rightSection={
                          <CaretRight size={15} color={theme.colors.gray[8]} weight="bold" />
                        }
                        size="sm"
                        variant="light"
                        color="gray"
                        onClick={() => {
                          trackAction(Actions.SUPPLIER_SELECTED_DETAILS);
                        }}
                      >
                        By {supplier.name}
                      </Button>
                    </Link>
                  </Flex>

                  <VerifiedButton onClick={() => setShowVerifiedModal(true)} activityDetailsPage />
                </Box>
                <Box
                  component="section"
                  aria-label="activity location"
                  className={classes.locationContainer}
                >
                  <Group
                    gap={'0px'}
                    wrap="nowrap"
                    onClick={() => scrollToMap({ alignment: 'start' })}
                  >
                    {activity.isOnline ? (
                      <Flex direction="row" align="center">
                        <ActionIcon variant="transparent" title="online video icon">
                          <VideoCamera size={18} weight="fill" color={theme.colors.blue[8]} />
                        </ActionIcon>
                        <Text className={classes.onlineBadge}>ONLINE</Text>
                      </Flex>
                    ) : (
                      <Flex align="center" pt={6}>
                        <ActionIcon
                          variant="transparent"
                          title="location pin icon"
                          size={'sm'}
                          style={{ justifyContent: 'start' }}
                        >
                          <MapPin size={18} weight="fill" color={theme.colors.pink[5]} />
                        </ActionIcon>
                        <Text
                          ml="2px"
                          className={classNames(classes.activityDetailsLink, classes.locationLink)}
                        >
                          {activity.locationName && activity.locationName + ', '}
                          {buildFullAddressFromLocation(addressFields, activity.location)}
                        </Text>
                      </Flex>
                    )}
                  </Group>
                </Box>
              </Grid.Col>
            )}

            <Grid.Col span={{ base: 12, sm: 6, md: 8 }} maw={'100%'}>
              <section className={classes.cardHeader} aria-label="activity header">
                {activity.additionalImageIds?.length > 0 && activity.cloudinaryImageId ? (
                  <ImageCarousel
                    allImageIds={[activity.cloudinaryImageId, ...activity.additionalImageIds]}
                    activityName={activity.name}
                    supplierName={supplier.name}
                    isActivityDetails
                    trackEvent={() => trackAction(Actions.ADP_CAROUSEL)}
                  />
                ) : (
                  getSingleImage()
                )}
                {socialProofBadge && (
                  <Badge
                    className={classNames(classes.badge, {
                      [classes.limitedSpotsBadge]:
                        socialProofBadge === SocialProofLabelEnum.limitedSpots,
                      [classes.popularBadge]: socialProofBadge === SocialProofLabelEnum.popular,
                      [classes.justAddedBadge]: socialProofBadge === SocialProofLabelEnum.justAdded,
                    })}
                  >
                    {socialProofBadge}
                  </Badge>
                )}
              </section>

              {getSocialProofBannerTitle(lastBookingTime, totalActivityBookings) && (
                <Box className={classes.pageSection}>
                  <Notification
                    withCloseButton={false}
                    radius={8}
                    classNames={{
                      root: classes.notificationRoot,
                      title: classes.notificationTitle,
                    }}
                    title={getSocialProofBannerTitle(lastBookingTime, totalActivityBookings)}
                  />
                </Box>
              )}
              {isMobile && (
                <>
                  <div className={classes.activityDetailsWrapper}>
                    <Flex justify="space-between" w="100%" align="end">
                      <Title mt="xs" order={1} className={classes.title}>
                        {activity?.seoPageTitle}
                      </Title>
                      <div className={classes.shareButtonWrapper}>
                        <ShareButton activityName={activity?.name} />
                      </div>
                    </Flex>
                    {showReviewsWidgets && (
                      <Box mt="sm" mr="md">
                        <RatingsBar
                          supplierId={supplier.id}
                          onClick={() => {
                            scrollToReviews();
                            trackAction(Actions.REVIEWS_STARS_CLICK_ADP);
                          }}
                        />
                      </Box>
                    )}
                    <Link href={`/supplier/${supplier.slug}`} passHref legacyBehavior>
                      <Button
                        component="a"
                        data-cy="supplierLink"
                        className={classes.supplierDetailsButton}
                        rightSection={
                          <CaretRight size={15} color={theme.colors.gray[8]} weight="bold" />
                        }
                        size="sm"
                        variant="light"
                        color="gray"
                        mt={showReviewsWidgets ? 'xs' : 'md'}
                        onClick={() => {
                          trackAction(Actions.SUPPLIER_SELECTED_DETAILS);
                        }}
                      >
                        {`By ${supplier.name}`}
                      </Button>
                    </Link>
                    <div>
                      <VerifiedButton
                        onClick={() => setShowVerifiedModal(true)}
                        activityDetailsPage
                      />
                    </div>
                    <Box component="section" aria-label="activity location">
                      <Group
                        gap={'0'}
                        wrap="nowrap"
                        onClick={() => scrollToMap({ alignment: 'start' })}
                      >
                        {activity.isOnline ? (
                          <Flex direction="row" align="center">
                            <ActionIcon variant="transparent" title="online video icon">
                              <VideoCamera size={18} weight="fill" color={theme.colors.blue[8]} />
                            </ActionIcon>
                            <Text className={classes.onlineBadge}>ONLINE</Text>
                          </Flex>
                        ) : (
                          <Flex align="center">
                            <ActionIcon variant="transparent" title="location pin icon" size={'sm'}>
                              <MapPin size={18} weight="fill" color={theme.colors.pink[5]} />
                            </ActionIcon>

                            <Text
                              ml="2px"
                              className={classNames(
                                classes.activityDetailsLink,
                                classes.locationLink,
                              )}
                            >
                              {activity.locationName && activity.locationName + ', '}
                              {buildFullAddressFromLocation(addressFields, activity.location)}
                            </Text>
                          </Flex>
                        )}
                      </Group>
                    </Box>
                  </div>
                </>
              )}
              <Box
                component="section"
                aria-label="about"
                className={classes.pageSection}
                pt="xs"
                pb="lg"
                mt={isMobile ? 'lg' : 'sm'}
              >
                <Title order={3} className={classes.sectionHeader} mb="xs">
                  About {activity.name}
                </Title>
                {activity.ageRange && (
                  <Flex direction="row" align="center" gap={8}>
                    <ChildFace color={theme.colors.gray[6]} />
                    <Text size="sm" fw={700} color={theme.colors.gray[6]}>
                      {activity.ageRange}
                    </Text>
                  </Flex>
                )}

                <Spoiler
                  maxHeight={208}
                  showLabel="Read more"
                  hideLabel="Read less"
                  classNames={{
                    control: classes.spoilerControl,
                  }}
                  onClick={() => {
                    trackAction(Actions.READ_MORE_DETAILS);
                  }}
                >
                  <Box className={classes.sectionText}>
                    <div dangerouslySetInnerHTML={{ __html: activity.description }} />
                  </Box>
                </Spoiler>
              </Box>

              {activity.isOnline && (
                <Box
                  className={classes.pageSection}
                  mb="md"
                  mt="sm"
                  component="section"
                  aria-label="Online call details"
                >
                  <Title order={3} className={classes.sectionHeader}>
                    Online call details
                  </Title>
                  <Text className={classes.sectionText}>{activity.onlinePublicInfo}</Text>
                </Box>
              )}

              <Box className={classes.pageSection} pb="sm" mb="lg" mt={isMobile ? 'md' : 'lg'}>
                <Group className={classes.iconsContainer}>
                  {activity.categories?.length > 0 &&
                    activity.categories?.map((category) => {
                      return (
                        <CategoryIcon
                          data-cy="category-badge"
                          category={category}
                          key={category.id}
                        ></CategoryIcon>
                      );
                    })}
                </Group>
              </Box>

              {isMobile && (
                <Box className={classes.pageSection} ref={sessionSelectRef}>
                  <SessionSelect
                    activityDetails={activityDetails}
                    handleGetTickets={handleGetTickets}
                    socialProofBadge={socialProofBadge}
                    selectedSession={selectedSession}
                    setSelectedSession={setSelectedSession}
                    setUserSelectedRadio={setUserSelectedRadio}
                    blockBookingTabActive={blockBookingTabActive}
                    setBlockBookingTabActive={setBlockBookingTabActive}
                    socialProofBannerTitleEventProperty={socialProofBannerTitleEventProperty}
                    utmParams={utmParams}
                    open={open}
                    opened={opened}
                    close={close}
                  />
                </Box>
              )}
              <Box className={classes.pageSection} mb="xl">
                {!isSoldOut && (
                  <Box aria-label="pricing" mb="md" hidden={!isMobile}>
                    <Group gap="xs">
                      <Ticket weight="fill" size={22} color={theme.colors.blue[8]} />
                      <Text fw={700} size="sm" c={theme.colors.gray[6]} lh={'18px'}>
                        Tickets available {getTicketPriceSummary(allTickets)}
                      </Text>
                    </Group>
                  </Box>
                )}
                {isMobile && isSubscription && (
                  <Button
                    leftSection={<Info size={24} />}
                    rightSection={<CaretRight size={16} weight="bold" />}
                    className={classes.subDetails}
                    onClick={() => setOpenSubsScheduleModal(true)}
                    mb="xl"
                  >
                    Review Subscription Details / Schedule
                  </Button>
                )}
                {(activity?.guardianToProvide || activity?.supplierToProvide) && (
                  <>
                    <Title order={3} className={classes.sectionHeader} pb="sm">
                      Things to know
                    </Title>
                    <Box hidden={!activity?.guardianToProvide} className={classes.toKnowSection}>
                      <Title order={6} className={classes.toKnowHeader} size="sm">
                        What you need to bring
                      </Title>
                      <Text className={classes.sectionText}>{activity.guardianToProvide}</Text>
                    </Box>

                    <Box hidden={!activity.supplierToProvide} className={classes.toKnowSection}>
                      <Title order={6} className={classes.toKnowHeader} size="sm">
                        What we provide
                      </Title>
                      <Text className={classes.sectionText}>{activity.supplierToProvide}</Text>
                    </Box>
                  </>
                )}
              </Box>
              {!activity.isOnline && (
                <Box className={classes.pageSection}>
                  <Title order={3} className={classes.sectionHeader} pb="sm">
                    Where to find us
                  </Title>

                  <Text size="sm" mb="sm">
                    {activity.locationName && (
                      <span data-testid="map header building">{`${activity.locationName}, `}</span>
                    )}
                    {buildFullAddressFromLocation(addressFields, activity.location)}
                  </Text>
                  <Box
                    component="section"
                    className={classes.mapWrapper}
                    aria-label="activity location map"
                    ref={mapRef}
                  >
                    <Box className={classes.mapInner}>
                      <ActivityMap activity={activity} />
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid.Col>
            {!isMobile && (
              <Grid.Col span={{ base: 6, md: 4 }}>
                <Box className={classes.sticky}>
                  <SessionSelect
                    activityDetails={activityDetails}
                    handleGetTickets={handleGetTickets}
                    socialProofBadge={socialProofBadge}
                    selectedSession={selectedSession}
                    setSelectedSession={setSelectedSession}
                    setUserSelectedRadio={setUserSelectedRadio}
                    blockBookingTabActive={blockBookingTabActive}
                    setBlockBookingTabActive={setBlockBookingTabActive}
                    socialProofBannerTitleEventProperty={socialProofBannerTitleEventProperty}
                    utmParams={utmParams}
                    open={open}
                    opened={opened}
                    close={close}
                  />
                  <Box className={classes.pageSection}>
                    {!isSoldOut && (
                      <Box aria-label="pricing" mb="md" hidden={isMobile}>
                        <Group gap="xs">
                          <Ticket weight="fill" size={22} color={theme.colors.blue[8]} />
                          <Text fw={700} size="sm" c={theme.colors.gray[6]} lh={'18px'}>
                            Tickets available {getTicketPriceSummary(allTickets)}
                          </Text>
                        </Group>
                      </Box>
                    )}
                  </Box>

                  {activity.bookingType === ActivityBookingTypeEnum.SUBSCRIPTION && (
                    <Button
                      leftSection={<Info size={24} />}
                      rightSection={<CaretRight size={16} weight="bold" />}
                      className={classes.subDetails}
                      onClick={() => setOpenSubsScheduleModal(true)}
                      my="xl"
                    >
                      Subscription Details / Schedule
                    </Button>
                  )}
                </Box>
              </Grid.Col>
            )}
            <Box className={classes.pageSection}>
              <div id="reviews" />
              {showReviewsWidgets && (
                <>
                  <div className={classes.reviewsWrapper}>
                    <Title order={3} className={classes.sectionHeader} mb="lg" ref={reviewsRef}>
                      What families are saying about {supplier.name}
                    </Title>

                    <ReviewsWidget supplierId={supplier.id} />
                  </div>
                </>
              )}
            </Box>
            <Box
              className={classNames(classes.pageSection, {
                //additional margin for single session basket
                [classes.bottomSection]:
                  !blockBookingTabActive &&
                  activity.bookingType === ActivityBookingTypeEnum.INSTANT,
              })}
              aria-label="supplier details"
            >
              <SocialLinksBanner
                supplierName={supplier.name}
                facebookLink={supplier.facebook}
                instagramLink={supplier.instagram}
                twitterLink={supplier.twitter}
                trackEvent={() => trackAction(Actions.ADP_SOCIALS)}
              />
              {!activity?.supplier?.isClaimed && (
                <Text className={classes.sectionText} mt="md">
                  Are you the supplier for this activity? <br />
                  Claim your activity listing{' '}
                  <a href={getClaimListingLink(activity.supplier)}>here</a>.
                </Text>
              )}
            </Box>
          </Grid>
          <VerifiedModal
            data-cy="modal"
            opened={showVerifiedModal}
            onClose={() => setShowVerifiedModal(false)}
          />
        </Container>
      )}
      {!isSubscription && !opened && <ScheduleBasket handleCheckoutButton={handleCheckoutButton} />}
      {openLeavingModal && (
        <LeavingModal
          opened={openLeavingModal}
          onClose={() => setOpenLeavingModal(!openLeavingModal)}
          goBack={() => goBack()}
          onClick={() => scrollToSessionSelect()}
          socialProofBadge={socialProofBadge}
          hasOneSpotLeft={
            (selectedSession?.spotsLeft === 1 && blockBookingTabActive) ||
            checkBasketItemsSpotsLeft()
          }
        />
      )}
      {openSubsScheduleModal && activity.subscriptionInfo && formatWeekdays && (
        <SubsSchedule
          opened={openSubsScheduleModal}
          onClose={() => setOpenSubsScheduleModal(!openSubsScheduleModal)}
          subName={activity.name}
          subDescription={activity.subscriptionInfo}
          offDates={activity.subscriptionOffDates}
          weekdays={formatWeekdays}
          startDate={activity.subscriptionStart}
        />
      )}
    </>
  );
};

export default ActivityDetails;
