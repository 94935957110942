import { Button, Text, Stack, Flex, useMantineTheme } from '@mantine/core';
import { Product } from 'types';
import { useBasket } from 'context/BasketContext';
import { FC, Fragment } from 'react';
import React from 'react';
import { getSpotsLeftText } from '../../utils';
import { trackAction, Actions } from 'utils/amplitude';
import classes from './SessionPicker.module.scss';
import classNames from 'classnames';
import queryString from 'query-string';

interface ISessionPicker {
  products: Product[];
  hasAvailableTickets: boolean;
  hideEndTimes: boolean;
}

const SessionPicker: FC<ISessionPicker> = ({ products, hasAvailableTickets, hideEndTimes }) => {
  const theme = useMantineTheme();
  const { basketItems, addToBasket, removeFromBasket, activityDetails, setSelectedSession } =
    useBasket();

  return (
    <Stack mt="lg" gap="xs" mx="-16px">
      <Flex ml="md" align="center" gap="xs">
        <Text className={classes.amountOfBasketSchedules}>{basketItems.length} </Text>
        <Text size="12px" c={theme.colors.gray[6]} fw={600}>
          Sessions selected
        </Text>
      </Flex>
      {products?.map((session, sessionIndex) => {
        const isProductInBasket = basketItems?.some(
          (basketItem) => basketItem.checkoutValue === session.checkoutValue,
        );

        const params = {
          checkoutKey: session?.checkoutKey,
          checkoutValue: session?.checkoutValue,
        };

        const paramsString = queryString.stringify(params);

        const waitlistLink = `/waitlist/${activityDetails?.activity.id}?${paramsString}`;

        return (
          <Fragment key={`${session.checkoutKey}-${session.checkoutValue}`}>
            <Flex
              key={session.checkoutValue}
              justify="space-between"
              mx={16}
              className={classes.sessionItem}
              wrap="wrap"
            >
              <Flex direction="column">
                <Text fw={700} size="sm" c={theme.colors.blue[8]}>
                  {hideEndTimes ? session.startTime : `${session.startTime} - ${session.endTime}`}
                </Text>
                {session.spotsLeft > 0 && hasAvailableTickets && (
                  <Text fw={400} size="sm" c={theme.colors.blue[8]}>
                    {getSpotsLeftText(session.spotsLeft)}
                  </Text>
                )}
              </Flex>
              {session.spotsLeft > 0 && hasAvailableTickets && (
                <Button
                  data-testid={`single-session-select-${sessionIndex}`}
                  className={classNames(classes.selectButton, {
                    [classes.removeButton]: isProductInBasket,
                  })}
                  onClick={() => {
                    if (isProductInBasket) {
                      removeFromBasket(session.checkoutValue);

                      return trackAction(Actions.SINGLE_SESSION_SELECT_REMOVE);
                    } else {
                      addToBasket(session);
                      setSelectedSession(session);
                      return trackAction(Actions.SINGLE_SESSION_SELECT);
                    }
                  }}
                >
                  {isProductInBasket ? 'Remove' : 'Select'}
                </Button>
              )}
              {(session.spotsLeft === 0 || !hasAvailableTickets) && (
                <Button
                  className={classes.waitlistButton}
                  component="a"
                  href={waitlistLink}
                  rel="noopener noreferrer"
                  onClick={() => trackAction(Actions.REQUEST_WAITLIST)}
                >
                  Join Waitlist
                </Button>
              )}
            </Flex>
          </Fragment>
        );
      })}
    </Stack>
  );
};

export default SessionPicker;
