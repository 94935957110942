import { ActionIcon, Card, Divider, Flex, Text, useMantineTheme } from '@mantine/core';
import { BasketSchedule, DateAvailabilityType, Product, SelectedTickets } from 'types';
import classes from '../ScheduleBasket.module.scss';
import { ClockClockwise, Ticket, X } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import { showTimesVaryBanner } from 'components/ActivityDetails/TimesAndAvailability/utils';
import classNames from 'classnames';
import { useBasket } from 'context/BasketContext';
import { useMediaQuery } from '@mantine/hooks';
import { PricingPolicyEnum } from 'enums';

interface IScheduleBasketItem {
  basketSchedule: BasketSchedule;
  index: number;
  ticketInfo: SelectedTickets | undefined;
}

const ScheduleBasketItem: React.FC<IScheduleBasketItem> = ({
  basketSchedule,
  index,
  ticketInfo,
}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  const { basketSchedules, setBasketSchedules, setBasketAccordionValue } = useBasket();

  const getSessionInstances = (filtered: DateAvailabilityType[]) => {
    const sessionInstances = filtered.length;
    return sessionInstances > 0 ? Number(sessionInstances - 1) : 0;
  };

  const getDateDisplay = (mainDate: string, additionalCount: number) => (
    <Flex mb={isMobile ? '4px' : 'xs'} align="center">
      <Text fw={isMobile ? 400 : 600} size="12px" mr="4px" c={theme.colors.blue[8]}>
        {mainDate}
      </Text>
      {additionalCount > 0 && (
        <Text fw={isMobile ? 400 : 600} size="12px" mr="4px" c={theme.colors.gray[6]}>
          +{additionalCount} more {additionalCount > 1 ? 'dates' : 'date'}
        </Text>
      )}
    </Flex>
  );

  const getBasketScheduleDate = (selectedSessions: Product[] | null) => {
    const firstSession = selectedSessions?.[0];
    const isBlockSession = firstSession?.checkoutKey === 'block';

    const getDateText = (date: string) => dayjs(date).format('DD MMM');
    const blockDates = firstSession?.allBlockDates;

    if (isBlockSession && blockDates && blockDates.length > 0) {
      const filteredBlockDates = blockDates.filter((date) => !date.isInPast && date.spotsLeft > 0);

      return getDateDisplay(
        getDateText(filteredBlockDates?.[0].date),
        getSessionInstances(filteredBlockDates),
      );
    } else if (selectedSessions) {
      return getDateDisplay(firstSession?.dateRange || '', selectedSessions.length - 1);
    }
  };

  const getMainTimes = (mainTimes: string, showTimesVary: boolean) => (
    <Flex mb={isMobile ? '4px' : 'xs'} align="center">
      <Text fw={600} size="12px" mr="xs" c={theme.colors.blue[8]}>
        {mainTimes}
      </Text>
      {showTimesVary && (
        <Flex className={classes.timeVaryBadge}>
          <Text fw={700} size="xs" mr="2px">
            Times vary
          </Text>
          <ClockClockwise size={16} weight="bold" />
        </Flex>
      )}
    </Flex>
  );

  const getBasketScheduleTimes = (selectedSessions: Product[] | null) => {
    const firstSession = selectedSessions?.[0];
    const isBlockSession = firstSession?.checkoutKey === 'block';

    if (isBlockSession) {
      const showTimesVary = showTimesVaryBanner(
        firstSession.startTime,
        firstSession.endTime,
        firstSession.allBlockDates,
      );

      return (
        <>
          <Flex align="center">
            <Text fw={600} size="12px" c={theme.colors.gray[6]} mb={isMobile ? '4px' : 'xs'}>
              {firstSession.startTime} - {firstSession.endTime}{' '}
            </Text>
            {showTimesVary && (
              <Flex
                align="center"
                className={classes.timeVaryBadge}
                ml={isMobile ? '4px' : 'sm'}
                mb={isMobile ? '4px' : 'xs'}
              >
                <Text fw={700} size={isMobile ? '10px' : 'xs'} mr="2px">
                  Times vary
                </Text>
                <ClockClockwise size={16} weight="bold" />
              </Flex>
            )}
          </Flex>
        </>
      );
    } else if (selectedSessions && firstSession) {
      const showTimesVary = () => {
        if (!selectedSessions) return false;
        const allMatchCoreTime = selectedSessions.every(
          (date) =>
            date.startTime === firstSession?.startTime && date.endTime === firstSession?.endTime,
        );

        return !allMatchCoreTime;
      };

      return getMainTimes(`${firstSession?.startTime} - ${firstSession?.endTime}`, showTimesVary());
    }
  };

  const TicketInfo = (numberOfAvailableSessions: number) => {
    if (!ticketInfo) return null;

    const getPrice = () => {
      if (ticketInfo?.pricingPolicy === PricingPolicyEnum.BLOCK_FULL_PRICE) {
        return `£${(ticketInfo.ticketPrice / 100).toFixed(2)}`;
      } else if (ticketInfo?.pricingPolicy === PricingPolicyEnum.FREE) {
        return 'Free';
      } else {
        return `£${((ticketInfo.ticketPrice / 100) * numberOfAvailableSessions).toFixed(2)}`;
      }
    };

    return (
      <>
        <Flex align="center" mt={isMobile ? '4px' : 0}>
          <Ticket size={14} color={theme.colors.blue[8]} />
          <Text ml={4} fw={600} size="12px" c={theme.colors.blue[8]}>
            {ticketInfo.name}{' '}
          </Text>
        </Flex>
        <Text mt="xs" fw={600} size="12px" c={theme.colors.blue[8]}>
          Total cost:{' '}
          <span className={classNames({ [classes.pinkText]: ticketInfo.ticketPrice === 0 })}>
            <b>{getPrice()}</b>
          </span>
        </Text>
      </>
    );
  };

  const handleDeleteBasketSchedule = () => {
    const filteredSchedules = basketSchedules.filter((_basketSchedule, i) => i !== index);
    setBasketSchedules(filteredSchedules);

    if (basketSchedules.length === 1) {
      setBasketAccordionValue(null);
    }
  };

  const numberOfAvailableSessions =
    basketSchedule.selectedSessions.length > 1
      ? basketSchedule.selectedSessions.length
      : basketSchedule.selectedSessions[0].numberOfSessionsAvailable;

  return (
    <Card className={classes.ticketCard}>
      <Flex justify="space-between" align="end">
        <Text fw={isMobile ? 700 : 600} size="12px" c={theme.colors.blue[8]} mb="xs">
          {basketSchedule.className}
        </Text>
        <Flex align="end">
          <ActionIcon variant="transparent" title="delete">
            <X
              color={theme.colors.gray[6]}
              className={classes.hoverState}
              onClick={() => handleDeleteBasketSchedule()}
            />
          </ActionIcon>
        </Flex>
      </Flex>
      {isMobile ? (
        <Flex align="center">
          {getBasketScheduleDate(basketSchedule?.selectedSessions)}
          <Divider orientation="vertical" mr="4px" color={theme.colors.gray[5]} />
          {getBasketScheduleTimes(basketSchedule?.selectedSessions)}
        </Flex>
      ) : (
        <>
          {getBasketScheduleDate(basketSchedule?.selectedSessions)}
          {getBasketScheduleTimes(basketSchedule?.selectedSessions)}
        </>
      )}
      {TicketInfo(numberOfAvailableSessions)}
    </Card>
  );
};

export default ScheduleBasketItem;
