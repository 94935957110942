import { Accordion, ThemeIcon, Divider, Box, useMantineTheme, Text, Flex } from '@mantine/core';
import { SubscriptionTrialTypeEnum } from 'enums';
import { useEffect, useMemo } from 'react';
import classes from './CheckoutTicketAccordionCard.module.scss';
import classNames from 'classnames';
import { CheckCircle, Ticket, XCircle } from '@phosphor-icons/react';
import { useCheckoutAttendeesFormContext } from 'context/CheckoutFormContext';
import { useCheckoutDetailsContext } from 'context/CheckoutDetailsContext';
import AttendeeInfoForm from '../AttendeeInfoForm/AttendeeInfoForm';

interface ICheckoutTicketAccordionCard {
  id: string;
  ticketName: string;
  ticketIndex: number;
  ticketId: string;
  subscriptionTrialInfo: {
    trialType?: SubscriptionTrialTypeEnum;
    trialLength?: number;
    trialCost?: number;
  };
}

interface IAccordionHeader {
  complete: boolean;
  label: string;
}

const CheckoutTicketAccordionCard: React.FC<ICheckoutTicketAccordionCard> = ({
  id,
  ticketName,
  ticketIndex,
  ticketId,
  subscriptionTrialInfo,
}) => {
  const form = useCheckoutAttendeesFormContext();
  const { accordionOpenId, erroredAccordions, setErroredAccordions, eligibleAttendees } =
    useCheckoutDetailsContext();
  const theme = useMantineTheme();
  const attendeesArray = form?.values.tickets[ticketIndex].attendees;
  const accordionOpen = accordionOpenId === `tickets.${ticketIndex}`;

  useEffect(() => {
    const errorKeys = Object.keys(form.errors);
    const accordionErrors = errorKeys.filter((key) => key.startsWith('tickets'));
    const accordionIdsWithErrors = accordionErrors.map(
      (accordionErrorKey: string) => `tickets.${accordionErrorKey.split('.')[1].slice(0, 1)}`,
    );
    const uniqueAccordionIdsWithErrors = Array.from(new Set(accordionIdsWithErrors));
    setErroredAccordions(uniqueAccordionIdsWithErrors);
  }, [form.errors, setErroredAccordions]);

  const erroredAccordion = erroredAccordions.includes(`tickets.${ticketIndex}`);

  const AccordionHeader = ({ complete, label }: IAccordionHeader) => {
    return (
      <Flex align="center">
        <Ticket size={24} weight="duotone" color={theme.colors.blue[8]} />
        <span className={classes.ticketHeader}>{label}</span>
        {!erroredAccordion ? (
          <ThemeIcon
            variant="transparent"
            color={complete ? theme.colors.lime[8] : theme.colors.gray[2]}
          >
            <CheckCircle
              color={complete ? theme.colors.lime[8] : theme.colors.gray[2]}
              size={32}
              weight="fill"
            />
          </ThemeIcon>
        ) : (
          <ThemeIcon color={theme.colors.red[5]} variant="transparent">
            <XCircle size={32} color={theme.colors.red[5]} weight="fill" />
          </ThemeIcon>
        )}
      </Flex>
    );
  };

  const isAttendeeInfoCompleted = (attendeeIndex: number) => {
    const attendee = form?.values.tickets[ticketIndex].attendees[attendeeIndex];
    if (!attendee) return false;

    const hasFullName = !!attendee.fullName;
    const isAdult = attendee.isAdult;
    const hasBirthDate = !isAdult && !!attendee.birthDate;
    const hasId = !!attendee.id;

    return hasId || (hasFullName && (isAdult || hasBirthDate));
  };
  const isTicketInfoComplete = useMemo(() => {
    if (!form) return false;
    if (form.values.tickets[ticketIndex]) {
      let isValid = true;

      form.values.tickets[ticketIndex].attendees.forEach((attendee, attendeeIndex) => {
        if (!isAttendeeInfoCompleted(attendeeIndex)) {
          isValid = false;

          return false;
        }
      });

      return isValid;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.tickets[ticketIndex]]);

  const ticketChildrenNames = useMemo(() => {
    return form.values.tickets[ticketIndex].attendees.map((attendee, index) => {
      const isLastAttendee = index + 1 === attendeesArray.length;
      const selectedAttendeeOption = eligibleAttendees?.find((option) => {
        if (option.id === attendee.id) {
          return option.fullName;
        }
      });
      if (selectedAttendeeOption) {
        return `${selectedAttendeeOption?.fullName}${isLastAttendee ? '' : ', '}`;
      } else {
        return `${attendee.fullName}${isLastAttendee ? '' : ', '}`;
      }
    });
  }, [eligibleAttendees, attendeesArray, form.values.tickets, ticketIndex]);

  return (
    <>
      <Accordion.Item value={id} className={classes.card} mt={0}>
        <Accordion.Control
          className={classNames({
            [classes.errorWarning]: erroredAccordion && !accordionOpen,
          })}
        >
          <AccordionHeader complete={isTicketInfoComplete} label={ticketName} />
          {!accordionOpen && isTicketInfoComplete && (
            <Text size="xs" className={classes.attendeeList}>
              {ticketChildrenNames}
            </Text>
          )}
        </Accordion.Control>
        <Accordion.Panel className={classes.accordionPanel} style={{ paddingTop: 'md' }}>
          {attendeesArray.map((attendee, attendeeIndex) => {
            return (
              <Box key={`Ticket ${ticketId} Attendee ${attendeeIndex + 1}`}>
                <AttendeeInfoForm
                  ticketIndex={ticketIndex}
                  subscriptionTrialInfo={subscriptionTrialInfo}
                  attendeeIndex={attendeeIndex}
                />
                {attendeesArray.length !== attendeeIndex + 1 && (
                  <Divider mt="lg" mb="sm" variant="dashed" />
                )}
              </Box>
            );
          })}
        </Accordion.Panel>
      </Accordion.Item>
      {erroredAccordion && !accordionOpen && (
        <Text component="p" c={theme.colors.red[5]} size="xs" mt={0} mb="md" ml="md">
          Please complete attendee information
        </Text>
      )}
    </>
  );
};

export default CheckoutTicketAccordionCard;
